import ApplicationController from "./application_controller";
import Dropzone from "dropzone"
import { getMetaValue, findElement, removeElement, insertAfter } from "./utils/helpers.js"

Dropzone.autoDiscover = false

export default class extends ApplicationController {
  static values = {
    url: String,
    applicationId: Number
  }
  static targets = [ "input" ]

  connect() {
    super.connect();
    this.dropZone = createDropZone(this)
    this.hideFileInput()
    this.bindEvents()
  }

  hideFileInput() {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"
  }

  bindEvents() {
    this.dropZone.on("addedfile", (file) => {
      //setTimeout(() => { file.accepted && createUploadController(this, file).start() }, 500)
    })

    this.dropZone.on('sending', (file, xhr, formData) => {
      formData.append('accreditation_evidence[][evidence_content_attributes][image]', file) 
      formData.append('accreditation_evidence[][evidence_content_attributes][accreditation_application_id]', this.applicationIdValue) 
    })

    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput)
    })

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort()
    })

    this.dropZone.on("processing", (file) => {
      //this.submitButton.disabled = true
    })

    this.dropZone.on("queuecomplete", (file) => {
      //this.submitButton.disabled = false
    })
    this.dropZone.on("success", (file, message) => {
      setTimeout(() => { file.previewElement.remove(); this.checkVisibleUploads(file) }, 3500)
    })
  }
  
  checkVisibleUploads(file) {
    if(this.element.querySelectorAll('.dz-preview').length == 0) {
      this.element.classList.remove('dz-started')
    }
    this.stimulate('PhotoEvidence#add')
  }


  get headers() { return { "X-CSRF-Token": getMetaValue("csrf-token") } }

  get url() { return this.inputTarget.getAttribute("data-direct-upload-url") }

  get maxFiles() { return this.data.get("maxFiles") || 1 }

  get maxFileSize() { return this.data.get("maxFileSize") || 256 }

  get acceptedFiles() { return this.data.get("acceptedFiles") }


  get form() { return this.element }

  get submitButton() { return findElement(this.form, "input[type=submit], button[type=submit]") }

}
class UploadController {
  constructor(source, file) {
    this.directUpload = createUpload(file, source.dropZone.options.url, this)
    this.source = source
    this.file = file
  }

  start() {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()
    const fileInput = this.file;
    let formData = new FormData();


    const options = {
      method: 'POST',
      body: formData,
      headers: {
        'X-CSRF-Token': getMetaValue("csrf-token"),
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    };
    
    fetch(this.source.dropZone.options.url, options).then(response => {
      if (response.ok) {
        this.emitDropzoneSuccess()
      } else {
        this.emitDropzoneError(response.statusText)
      }
    })
  }

// Private
  createHiddenInput() {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr)
    this.emitDropzoneUploading()
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr
    this.xhr.upload.addEventListener("progress", event => this.uploadRequestDidProgress(event))
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element
    const progress = event.loaded / event.total * 100
    findElement(this.file.previewTemplate, ".dz-upload").style.width = `${progress}%`
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit("processing", this.file)
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit("error", this.file, error)
    this.source.dropZone.emit("complete", this.file)
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit("success", this.file)
    this.source.dropZone.emit("complete", this.file)
  }
}

// Top level...
function createUploadController(source, file) {
  return new UploadController(source, file)
}

function createUpload(file, url, controller) {
  //fetch(url, {
  //  method: "POST",
  //  headers: { "Content-Type": file.type },
  //  body: file
  //})
}
function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.urlValue,
    headers: controller.headers,
    maxFiles: 10,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    uploadMultiple: true,
    autoQueue: true
  })
}
